import SocialEntityRepository from '@/shared/http/repositories/socialProject/social-entity'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import statusEnum from '@/shared/enums/status'
import Student from '@/shared/models/student'
import Roles from '@/shared/mixins/hasRoles'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  mixins: [Roles],

  data: () => ({
    criteria: {},
    selectAll: false,
    optionsStatus: Object.values(statusEnum),
    selecteds: [],
    studentsIds: [],
    socialEntities: [],
    students: [],
    emptyText: 'Nenhum aluno selecionado.',
    fields: [
      {
        key: 'selected',
        label: 'SELEÇÃO',
        class: 'left-header-border text-center'
      },
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center'
      },
      {
        key: 'statusText',
        label: 'SITUAÇÃO',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 20,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Situação em Lote',
            active: true
          }
        ]
      }
    },

    disableSearchBtn() {
      if(!this.criteria.socialEntityId) {
        return true
      }

      return false
    },

    disableInactiveBtn() {
      if(!this.selecteds.length) {
        return true
      }

      return false
    }
  },

  created() {
    this.getAllSocialEntity()
  },

  methods: {
    getAllSocialEntity() {
      loading.push()
      SocialEntityRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.socialEntities = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar entidades sociais', 'ERRO')
        })
    },

    getAll() {
      if(!this.criteria.socialEntityId) return

      loading.push()
      StudentRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.students = res.data.data.map(student => {
            return new Student(student)
          })
          // this.pagination = {
          //   count: res.data.count,
          //   limit: res.data.pagingLimit,
          //   index: res.data.pagingIndex
          // }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    },

    inactiveStudents() {
      if(!this.selecteds.length) return

      loading.push()
      StudentRepository.InactiveStudents(this.selecteds)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

         
          loading.pop()
          toast.success('Alunos inativados com sucesso.', 'INATIVAÇÃO')
          this.getAll()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao inativar alunos', 'ERRO')
        })
    },

    onSelectAllRows(items) {
      this.selecteds = items.map(i => i.id)
    },

    selectAllRows() {
      if(this.selectAll) {
        this.$refs.selectableTable.selectAllRows()
      } else {
        this.$refs.selectableTable.clearSelected()
      }
    }
  }
}
